import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const ordersModule = {
    state() {
        return {
            orderId: null,
            order: null,
        };
    },
    mutations: mutations,
    actions: actions,
    getters: getters
};