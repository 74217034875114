<template>
  <section class="hero">
    <div class="hero-bg" :style="{ 'background-image': 'url(' + keyVisualUrl + ')' }"></div>
    <div class="grid grid-pad">
      <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
        <div class="hero-message animated fadeInUp">
          <h1 class="title">{{ homeTitle }}</h1>
          <div class="text" v-html="homeText"></div>
          <div class="buttons">
            <router-link to="/product-catalog" class="btn btn-secondary">{{
              browseCatalog
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "AppHero",
  computed: {
    ...mapGetters(["keyVisualUrl", "homeTitle", "homeText", "browseCatalog"]),
  },
};
</script>

<style lang="scss">
@import "@/global-styles/variables";
.hero {
  position: relative;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 75%;
    display: block;
    background-size: cover;
    background-position: center center;
  }
}

.hero-message {
  background: $white;
  border-radius: 0.8rem;
  box-shadow: $shadow-1;
  text-align: center;
  padding: 5rem;
  margin: 30rem 0 0 0;
  z-index: 1;
  position: relative;

  .title {
    font-size: 3.2rem;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 2rem;
    color: $color-primary;
  }

  .text {
    h2 {
      font-size: 4.2rem;
      font-weight: 400;
      line-height: 4.2rem;
      margin-bottom: 3rem;
    }

    p {
      font-size: 1.8rem;

      strong {
        color: $color-primary;
      }
    }
  }

  .buttons {
    margin-top: 2rem;

    .btn {
      margin: 0.8rem;
    }
  }
}
</style>
