export default {
    getStores: (state) => {
        return state.stores;
    },

    getStoreById: (state) => (id) => {
        return state.stores.find(store => store.id === id);
    },

    storeByName: (state) => (name) => {
        return state.stores.find(store => store.name === name);
    },

    getStoreLocations: (state) => {
        const locations = [];
        state.stores.forEach(store => {
            const location = { center: {lat: store.latitude, lng: store.longitude}};
            locations.push(location);
        });
        return locations;
    }
}