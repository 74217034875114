import axiosInstance from './axiosConfig';

const responseBody = response => response.data;

const requests = {
    get: (url, config) => axiosInstance.get(url, config).then(responseBody),
    post: (url, body, config) => axiosInstance.post(url, body, config).then(responseBody),
    put: (url, body, config) => axiosInstance.put(url, body, config).then(responseBody),
    del: (url, body, config) => axiosInstance.delete(url, body, config).then(responseBody),
};

export default requests;