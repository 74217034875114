import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const pagesModule = {
    state() {
        return {
            cookieContent: null,
            privacyContent: null,
            termsContent: null
        };
    },
    mutations: mutations,
    actions: actions,
    getters: getters
};