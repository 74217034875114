<template>
  <div id="app">
    <div class="fixed-container" v-if="isSpinnerLoading">
      <LoadingSpinner />
    </div>
    <AppHeader />
    <router-view />
    <AppFooter />
    <WarningMessageModal
      v-if="$store.state.showErrorWarning"
      :title="$store.state.errorTitle"
      :text="$store.state.errorMessage"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/ui/LoadingSpinner.vue";
import AppHeader from "@/components/navigation/AppHeader";
import AppFooter from "@/components/navigation/AppFooter";
import WarningMessageModal from "@/components/modals/WarningMessageModal";

export default {
  name: "App",
  data() {
    return {
      isSpinnerLoading: true,
    };
  },
  components: { LoadingSpinner, AppHeader, AppFooter, WarningMessageModal },
  created() {
    this.$store.dispatch("fetchSettings");
    this.$store.dispatch("fetchLanguages");
    this.$store.dispatch("fetchTexts");
    this.$store.dispatch("fetchProducts");

    const token = localStorage.getItem("token");

    if (token) {
      this.$store.dispatch("autoLogin", token);
    }

    window.addEventListener("load", this.onWindowLoad);
  },
  methods: {
    onWindowLoad() {
      this.isSpinnerLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

@import "@/global-styles/mixins";
@import "@/global-styles/variables";
@import "@/global-styles/cre8ion-simplegrid";
@import "@/global-styles/flex";
@import "@/global-styles/default";
@import "@/global-styles/forms";
</style>
