import agent from '../../api/agent';

export default {
    fetchProduct: async ({commit}, id) => {
        try {
            const product = await agent.products.details(id);
            commit('SET_PRODUCT', product);
        } catch (error) {
            console.error(error);
        }
    },

    fetchProducts: async ({commit}) => {
        try {
            const products = await agent.products.all();
            commit('SET_PRODUCTS', products);
        } catch (error) {
            console.error(error);
        }
    }
}